@import "~antd/dist/antd.css";
@import "./design-system.scss";
@import "./helpers.scss";
@import "react-h5-audio-player/src/styles.scss";
@import "@pensil-inc/web-sdk/lib/esm/styles/index.css";
// @import "react-pdf/dist/esm/Page/AnnotationLayer.css";
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Open+Sans:wght@400;600;700&display=swap");

//Use this for global next variables.
#__next {
  height: 100%;
}

html {
  overflow: overlay;
}

body {
  color: $white;
  background-color: $grey-darker;
  font-family: "Open Sans", sans-serif;
  @extend .lms-scrollbar;
}

//global antd
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.75);
}

.ant-col {
  min-height: unset;
}

.ant-form-item-has-error :not(.ant-input-disabled).ant-input {
  background-color: transparent;
  border-color: $error-clr;
  &:hover {
    background-color: transparent;
  }
}

.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
  border-color: $error-clr !important;
  &:hover {
    background: $grey-dark !important;
  }
}

// Pagination Styles Override
.ant-pagination-item {
  font-family: "Open Sans", sans-serif;
  border: none;
  line-height: 32px;
  border-radius: 50%;
}

.ant-pagination-item a {
  font-family: "Open Sans", sans-serif;
  color: $black;
}

.ant-pagination-item:hover {
  background-color: $grey-lightest;
}

.ant-pagination-item:hover a {
  color: $grey-darker;
}

.ant-pagination-item-active {
  border: none;
  border-radius: 50%;
  background-color: #010101;
}

.ant-pagination-item-active a {
  font-family: "Open Sans", sans-serif;
  color: #fff;
}

.ant-pagination-next .ant-pagination-item-link {
  font-family: "Open Sans", sans-serif;
  border-radius: 50%;
}

.ant-pagination-next .ant-pagination-item-link:hover {
  border: none;
}

.ant-pagination-prev .ant-pagination-item-link {
  font-family: "Open Sans", sans-serif;
  border-radius: 50%;
}

.ant-pagination-prev .ant-pagination-item-link:hover {
  border: none;
}

.ant-pagination-disabled {
  border-radius: 50%;
}

.ant-pagination-next .ant-pagination-prev {
  font-family: "Open Sans", sans-serif;
  border: none;
  border-radius: 16px;
}

.ant-pagination-item-link:hover span.anticon {
  color: $black;
}

.ant-pagination-item-link[disabled]:hover span.anticon {
  color: $grey-lightest;
}

.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: $white;
}

.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: $white;
}

.ant-popover {
  z-index: 10 !important;
}

.ant-popover-inner-content {
  padding: 0 !important;
}
