@import "./design-system.scss";

// Text classes
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-nowrap {
  white-space: nowrap;
}
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-break {
  word-wrap: break-word;
}

//Font classes
.f-32 {
  font-size: $large;
}
.f-28 {
  font-size: $text-28;
}
.f-24 {
  font-size: $text-24;
}
.f-22 {
  font-size: $text-22;
}
.f-20 {
  font-size: $text-20;
}
.f-18 {
  font-size: $text-18;
}
.f-16 {
  font-size: $text-16;
}
.f-14 {
  font-size: $text-14;
}
.f-12 {
  font-size: $text-12;
}
.f-10 {
  font-size: $text-10;
}

.font-italic {
  font-style: italic;
}
.font-bold {
  font-weight: bold;
}

.font-opensans {
  font-family: $open-sans;
}
.font-lato {
  font-family: $lato;
}

//Color classes
.clr-base {
  color: $grey-darker;
}
.clr-black {
  color: $black;
}
.clr-white {
  color: $white;
}
.clr-yellow {
  color: $primary-yellow-regular;
}
.clr-link {
  color: $blue-light;
}

// Vertical Alignment classes
.v-align-top {
  vertical-align: top;
}
.v-align-bottom {
  vertical-align: bottom;
}
.v-align-middle {
  vertical-align: middle;
}

//Display classes
.d-none {
  display: none;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
.d-grid {
  display: grid;
}
.d-inline {
  display: inline;
}
.d-inline-block {
  display: inline-block;
}
.d-inline-flex {
  display: inline-flex;
}

// Margin zero classes
.m-0 {
  margin: 0;
}
.mt-0 {
  margin-top: 0;
}
.mr-0 {
  margin-right: 0;
}
.mb-0 {
  margin-bottom: 0;
}
.ml-0 {
  margin-left: 0;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

// Margin space 4 classes
.mt-4 {
  margin-top: 4px;
}
.mr-4 {
  margin-right: 4px;
}
.mb-4 {
  margin-bottom: 4px;
}
.ml-4 {
  margin-left: 4px;
}
.my-4 {
  margin-top: 4px;
  margin-bottom: 4px;
}
.mx-4 {
  margin-left: 4px;
  margin-right: 4px;
}

// Margin space 8 classes
.mt-8 {
  margin-top: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.my-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.mx-8 {
  margin-left: 8px;
  margin-right: 8px;
}

// Margin space 16 classes
.mt-16 {
  margin-top: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.my-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.mx-16 {
  margin-left: 16px;
  margin-right: 16px;
}

// Margin space 24 classes
.mt-24 {
  margin-top: 24px;
}
.mr-24 {
  margin-right: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.ml-24 {
  margin-left: 24px;
}
.my-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.mx-24 {
  margin-left: 24px;
  margin-right: 24px;
}

// Margin space 32 classes
.mt-32 {
  margin-top: 32px;
}
.mr-32 {
  margin-right: 32px;
}
.mb-32 {
  margin-bottom: 32px;
}
.ml-32 {
  margin-left: 32px;
}
.my-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}
.mx-32 {
  margin-left: 32px;
  margin-right: 32px;
}

// Padding zero classes
.p-0 {
  padding: 0;
}
.pt-0 {
  padding-top: 0;
}
.pr-0 {
  padding-right: 0;
}
.pb-0 {
  padding-bottom: 0;
}
.pl-0 {
  padding-left: 0;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}

// Padding space 4 classes
.pt-4 {
  padding-top: 4px;
}
.pr-4 {
  padding-right: 4px;
}
.pb-4 {
  padding-bottom: 4px;
}
.pl-4 {
  padding-left: 4px;
}
.py-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.px-4 {
  padding-left: 4px;
  padding-right: 4px;
}

// Padding space 8 classes
.pt-8 {
  padding-top: 8px;
}
.pr-8 {
  padding-right: 8px;
}
.pb-8 {
  padding-bottom: 8px;
}
.pl-8 {
  padding-left: 8px;
}
.py-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}

// Padding space 16 classes
.pt-16 {
  padding-top: 16px;
}
.pr-16 {
  padding-right: 16px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pl-16 {
  padding-left: 16px;
}
.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

// Padding space 24 classes
.pt-24 {
  padding-top: 24px;
}
.pr-24 {
  padding-right: 24px;
}
.pb-24 {
  padding-bottom: 24px;
}
.pl-24 {
  padding-left: 24px;
}
.py-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.px-24 {
  padding-left: 24px;
  padding-right: 24px;
}

// Padding space 32 classes
.pt-32 {
  padding-top: 32px;
}
.pr-32 {
  padding-right: 32px;
}
.pb-32 {
  padding-bottom: 32px;
}
.pl-32 {
  padding-left: 32px;
}
.py-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.px-32 {
  padding-left: 32px;
  padding-right: 32px;
}

.pointer {
  cursor: pointer;
}

//Fontawesome classes
.solid-large {
  width: $solid-large !important;
  height: $solid-large;
}
.regular-large {
  width: $regular-large !important;
  height: $regular-large;
}
.solid-medium {
  width: $solid-medium !important;
  height: $solid-medium;
}
.regular-medium {
  width: $regular-medium;
  height: $regular-medium;
}
.solid-small {
  width: $solid-small;
  height: $solid-small;
}
.regular-small {
  width: $regular-small;
  height: $regular-small;
}
.solid-tiny {
  width: $solid-tiny;
  height: $solid-tiny;
}
.regular-tiny {
  width: $regular-tiny;
  height: $regular-tiny;
}
