@import "@styles/design-system.scss";

//Default styles
.customToastMessage {
  max-width: 400px;
  margin: 0 auto;
  @media screen and (max-width: $tablet_width) {
    max-width: unset;
  }
  .toastTitle {
    font-family: $open-sans;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: $white;
    margin: 0;
  }

  .toastDescription {
    font-family: $open-sans;
    font-size: 16px;
    line-height: 24px;
    color: $white;
    margin: 4px 0 0;
  }
}

//Toast positive styles
.toastPositive {
  :global {
    .ant-message-notice-content {
      padding: 16px 24px;
      background: $green-dark;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      .ant-message-custom-content {
        display: inline-flex;
        font-size: $text-16;
        color: $white;
        .svg-inline--fa {
          margin-top: 3px;
        }
        span {
          text-align: left;
          margin-left: 16px;
        }
      }
    }
  }
}

//Toast negative styles
.toastNegative {
  :global {
    .ant-message-notice-content {
      padding: 16px 24px;
      background: $red-dark;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      .ant-message-custom-content {
        display: inline-flex;
        font-size: $text-16;
        color: $white;
        .svg-inline--fa {
          margin-top: 3px;
        }
        span {
          text-align: left;
          margin-left: 16px;
        }
      }
    }
  }
}

//Toast warning styles
.toastWarning {
  :global {
    .ant-message-notice-content {
      padding: 16px 24px;
      background: $orange-dark;
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      .ant-message-custom-content {
        display: inline-flex;
        font-size: $text-16;
        color: $white;
        .svg-inline--fa {
          margin-top: 3px;
        }
        span {
          text-align: left;
          margin-left: 16px;
        }
      }
    }
  }
}
